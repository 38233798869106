import React from 'react'
import { FloatButton } from 'antd'

const HelperButtons = () => {

  return (
    <FloatButton.Group>
      <FloatButton.BackTop visibilityHeight={50} />
    </FloatButton.Group>
  )
}

export default HelperButtons
